/* eslint-disable max-len */
import styled from "styled-components";

const S = {
    container: styled.div`
    display: flex;
    min-height: 50vh;
    align-items: center;
    justify-content: center;
`,
};

export default S;
